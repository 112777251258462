html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include sans-serif-font; //originally serif-font
    font-size: 17px; //added by L, should match p paragraph size below
    line-height: 1.8; //added by L
}

hr {
    border-color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
}

hr.light {
    border-color: white;
}

a {
    @include transition-all;
    color: $theme-primary;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include serif-font; //originally sans-serif-font
}

p {
    font-size: 17px; //originally 18px
    line-height: 1.8;
    margin-bottom: 20px;
}

.bg-primary {
    background-color: #fff; //main bg color of segments, like of big main LJ banner area. was $theme-primary
    color: $theme-bodycopy; //added, color of LMJ in main LJ banner
}

.bg-dark {
    background-color: $theme-dark;
    color: white;
}

.text-faded {
    color: rgba(black, .7); //leiah changed, was white
}

.expanded { //creating a p class that looks similar to box overlay captions with tracking
    letter-spacing: 3px;
    text-transform: uppercase;
    font-weight: 300;
}

section {
    padding: 100px 0 50px 0; //padding on things called sections. top, right, bottom, left
}

aside {
    padding: 50px 0;
}

.no-padding {
    padding: 0;
}

// Navigation

.navbar-default {
    background-color: white; //this is the bg color on mobile
    border-color: transparent; //leiah changed, was rgba($theme-dark, .5), this is border color
    @include serif-font; //originally sans-serif-font
    @include transition-all;
    .navbar-header .navbar-brand {
        color: $theme-primary;
        font-size: 24px; //added to change size of main LMJ in top nav
        @include serif-font; //originally sans-serif-font
        font-weight: 100; //originally 700
        text-transform: none; //leiah changed, was uppercase
        &:hover,
        &:focus {
            color: darken($theme-primary, 10%);
        }
    }
    .nav {
        > li {
            > a,
            > a:focus {
                text-transform: none;
                font-weight: 600; //originally 700
                font-size: 18px; //leiah changed, was 13. size of links in top nav
                color: $theme-primary; //leiah changed, was $theme-dark. this is color of links in nav hamburger menu
                &:hover {
                    color: rgba($theme-primary, .5); //leiah changed, was $theme-primary, .5, color of links on hover in hamburger menu
                }
            }
            &.active {
                > a,
                > a:focus {
                    color: rgba($theme-primary, .5) !important; //leiah changed, was $theme-primary, this is the color of active link on nav
                    background-color: transparent;
                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    @media (min-width: 768px) {
        background-color: white; //leiah changed, was transparent. main nav bg color on big screen.
        border-color: transparent; //leiah changed, was rgba(white, .3)
        .navbar-header .navbar-brand {
            color: $theme-primary; //leiah changed, was rgba(white, .7) . main color of LMJ on nav!
            &:hover,
            &:focus {
                color: rgba($theme-primary, .5); //leiah changed, was white. color of hover links.
            }
        }
        .nav > li > a,
        .nav > li > a:focus {
            color: $theme-primary; //leiah changed, was rgba(white, .7), color of nav links on right-hand side
            &:hover {
                color: rgba($theme-primary, .5); //leiah changed, was white. color of hover links on right side.
            }
        }
        &.affix {
            background-color: white;
            border-color: #ccc; //leiah changed, was rgba($theme-dark, .5), this is color of border on nav when scrolling down. made it super light gray #ccc so you can see differentiation between bg white while it scrolls over stuff.
            .navbar-header .navbar-brand {
                color: $theme-primary;
                font-size: 18px; //originally 14px (size of LMJ is up on line 81)
                &:hover,
                &:focus {
                    color: darken($theme-primary, 10%);
                }
            }
            .nav > li > a,
            .nav > li > a:focus {
                color: $theme-primary; //leiah changed, was $theme-dark, color of links in nav when you scroll down
                &:hover {
                    color: rgba($theme-primary, .5); //leiah changed, was $theme-primary
                }
            }
        }
    }
}

// Homepage Header

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/header.jpg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 300;
                color: rgba(white, .7); //originally rgba(white, .7)
                font-size: 16px; //originally 16px
                margin-bottom: 50px;
            }
        }
    }
    @media (min-width: 768px) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 16px; //originally 18px
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}

// Sections & Portfolio Box

.section-heading {
    margin-top: 50px; //leiah changed, this is space above anywhere that calls this class, like on h2 on logo header
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: 992px) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
}

.portfolio-box {
    position: relative;
    display: block;
    max-width: 650px;
    margin: 0 auto;
    .portfolio-box-caption {
        color: $theme-bodycopy; //originally white, project link color
        opacity: 1; //originally 0 to only show on hover
        display: block;
        background: rgba( #fff, .85 ); //originally $theme-primary, .9
        position: absolute; //orig absolute
        bottom: 8%; //orig top 0 - sets margin under overlay
        //left: 15%;
        text-align: center; //set below
        width: 60%; //orig 100
        height: 30%; //originally 100, bg covered entire square
        @include transition-all;
        .portfolio-box-caption-content {
            width: 100%;
            text-align: left;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            .project-category,
            .project-name {
                @include serif-font; //originally sans-serif-font
                padding: 0 15px;
            }
            .project-category { //category is the smaller name above primary Project name
                @include sans-serif-font;
                text-transform: uppercase;
                font-weight: 300; //originally 600
                font-size: 14px; //originally 14px but change below instead
                letter-spacing: 3px; //added to add tracking/kerning
                color: $theme-bodycopy; //added to define category text color
            }
            .project-name {
                font-size: 18px; //was 18px but change below instead
                line-height: 1.2; //added to adjust leading
            }
        }
    }
    &:hover { //changes what happens on mouseover
        .portfolio-box-caption {
            opacity: 1;
            background: rgba( $theme-primary, 1 ); //added to change bg on hover
            //color: #fff; //added to change link color on hover, if necessary
        }
    }
    @media (min-width: 768px) {
        .portfolio-box-caption {
            .portfolio-box-caption-content {
                .project-category {
                    font-size: 12px; //was 16px
                }
                .project-name {
                    font-size: 24px; //was 22px
                }
            }
        }
    }
}

.call-to-action {
    h2 {
        margin: 0 auto 20px;
    }
}

// Bootstrap Overrides
.text-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

// Button Styles
.btn-default {
    @include button-variant($theme-dark, white, white);
}

.btn-primary {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;
}

.btn-xl {
    padding: 15px 30px;
}

// Contact
#contact .fa {
    color: $theme-dark;
    font-size: 2em; //originally 4em
}

// Extras
// -- Highlight Color Customization
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

.coloring-pages-drop-shadows img {
    box-shadow: 5px 7px 10px #D7D7D7;
    margin: 10px;
    border: 1px solid #E4E4E4;
  }

//this is leiah's custom 'black tape' blacktape  style!! to use, put <p class="blacktape"> around the text you want to become a blacktape banner. or call it in <a class="blacktape" href=""> to make just a small button
.blacktape {
    color: #fff;
    background: #222;
    padding-top:7px;
    padding-right:10px;
    padding-bottom:6px;
    padding-left:10px;
    margin-bottom:0px; //margin is the gaps between outside of box and text surrounding it
    margin-top:0px;
    margin-left: 0px; //a negative number here on marign-left and margin-right allow the tape to extend past its containing box
    margin-right:0px;
    font-color:#fff;
    text-align: center;
    font-family: 'Courier';
    text-transform: none;
    font-size: 1.5em;
}

//this changes the color of the highlight color when your mouse selects text! cool

::selection {
  background: $theme-primary;
}

//added this to format tables

td, tr, th {
  border: 1px solid $theme-primary;
  vertical-align: baseline;
  padding: 10px;
}

//added this to float About image

img.float {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}
